import React from 'react';

import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import httpsBrowser from 'images/svg/https.svg';
import { styles } from './IdentityAndData.style';

function IdentityAndData({ classes }: WithStyles<typeof styles>) {
    return (
        <Box className={classes.root}>
            <Box className={classes.titleContainer}>
                <p className="title">PROTECCIÓN</p>
                <p id="sub" className="title">
                    DE IDENTIDAD
                </p>
                <p className="title">Y DATOS</p>
            </Box>

            <Box className={classes.bigDescription}>
                <p>
                    Protege tus fondos, aprende cómo evitar fraudes y minimizar las vulnerabilidades que tenga tu
                    cuenta.
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">¿Qué es la identidad?</p>
                <p>
                    Incluye todos los datos que permiten identificar a una persona como nombre completo, teléfono
                    particular, domicilio particular, fotografías, huellas dactilares, número de licencia y/o de
                    seguridad social, información financiera o médica.
                </p>

                <p className="subtitle">Robo de identidad</p>
                <p>
                    Es la obtención, transferencia o utilización indebida de datos personales ajenos, para cometer
                    fraudes o delitos. Si sucediese esto, no sólo puedes perder dinero, sino también puede afectar a tu
                    historial financiero.
                </p>
            </Box>

            <Box className={classes.bigDescription}>
                <p>
                    A continuación, te presentamos algunas de las formas de fraude y cómo puedes evitar ser víctima de
                    una de ellas:
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Phishing</p>
                <p>
                    A través de correos electrónicos que simulan ser de una institución legítima, buscan que el usuario
                    conteste revelando información personal o financiera como números de tarjetas de crédito, claves,
                    datos de cuentas.
                </p>
                <hr />
                <p>
                    No respondas a ningún correo electrónico donde se solicite información personal o financiera. No dar
                    clic en ningún hipervínculo.
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Pharming</p>
                <p>
                    A través de un correo electrónico, cuando el usuario lo abre, se instala un código en el equipo
                    personal modificando determinados archivos, para que la próxima vez que ingreses al portal de tu
                    entidad financiera te desvíen a sitios web falsos.
                </p>
                <hr />
                <p>
                    Instala y actualiza –con frecuencia– en tu computadora paquetes de seguridad (firewall, antispyware)
                    que protejan tu equipo contra virus y otras amenazas.
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Vishing</p>
                <p>
                    En una llamada, probablemente de un número desconocido, una grabación te alerta de un supuesto
                    fraude con tu tarjeta de crédito o débito. Te indican un número telefónico al que debes llamar de
                    inmediato. Cuando hablas, te responde otra grabación que te pide que ingreses, mediante el teclado
                    telefónico, los datos de tu tarjeta
                </p>
                <hr />
                <p>¡Cuelga la llamada rápidamente si te solicitan teclear tus datos o te piden información!</p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Smishing</p>
                <p>
                    El nombre viene de las siglas SMS. Son mensajes con hipervínculos a “promociones exclusivas” o
                    “premios” para infectar con virus al dispositivo.
                </p>
                <hr />
                <p>
                    Evita dar clic a hipervínculos en mensajes de números desconocidos. Borra inmediatamente el mensaje
                    y bloquea el número telefónico.
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Trashing</p>
                <p>
                    Rescatando documentos de la basura con información personal y de las cuentas para usurpación de
                    identidad.
                </p>
                <hr />
                <p>
                    Destruye la información con marcadores o tijeras, entre otros, y sepárala en diferentes
                    contenedores.
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Skimming (clonación)</p>
                <p>
                    Con un pequeño dispositivo (skimmer), los delincuentes copian y almacenan los datos de la banda
                    magnética de la tarjeta, ya sea al pagar o retirar dinero en un cajero automático.
                </p>
                <hr />
                <p>
                    Al pagar con tarjeta, asegúrate de ver la terminal y no perder de vista la tarjeta. En cajeros
                    automáticos, revisar que la ranura de tarjetas no tenga ningún dispositivo extraño.
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Spam</p>
                <p>Correo basura. Correo electrónico enviado a varias direcciones sin que haberse solicitado.</p>
                <hr />
                <p>Te recomendamos bloquear al remitente y eliminar estos mensajes.</p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Spim</p>
                <p>
                    Es un tipo de Spam. En esta variante, el correo electrónico incluye hipervínculos, virus,
                    promociones y ofertas falsas.
                </p>
                <hr />
                <p>Te recomendamos no dar clic en ningún hipervínculo.</p>
            </Box>

            <Box className={classes.subSection}>
                <p className="subtitle">Doxing</p>
                <p>Consiste en obtener y publicar información sensible de una persona, sin su consentimiento.</p>
                <hr />
                <p>
                    Acude sólo a instituciones financieras legalmente establecidas en caso de requerir un préstamo o
                    cualquier otro tipo de servicio financiero.
                </p>
                <p>
                    Evita descargar aplicaciones móviles de dudosa reputación y cuando descargues cualquier app,
                    verifica qué permisos les concedes.
                </p>
            </Box>

            <Box className={classes.bigDescription}>
                <p>Otras formas de prevención de fraude:</p>
            </Box>

            <Box className={classes.subSection}>
                <ol>
                    <li>
                        Recuerda que ni Cuenca, ni ninguna otra institución financiera, solicita información con datos
                        confidenciales por correo electrónico. Muchos menos des clic en los hipervínculos.
                    </li>
                    <li>
                        Verifica que se trata de una página legítima y que incluya los sistemas de seguridad MasterCard
                        Securecode y Verified by Visa.
                    </li>
                    <li>
                        Crea contraseñas fuertes:
                        <ol type="a">
                            <li>No utilices la misma contraseña para distintos servicios.</li>
                            <li>
                                Evita usar contraseñas fáciles de adivinar como tu nombre, tu fecha de nacimiento,
                                números telefónicos, series numéricas o alfabéticas (1234 o abcde).
                            </li>
                            <li>No compartas las contraseñas con nadie.</li>
                        </ol>
                    </li>
                    <li>
                        Si vas a realizar compras o pagos en línea, verifica que la dirección electrónica sea genuina y
                        segura. La página donde realizarás el pago debe tener en el protocolo una letra s (https o
                        shttp); además, en la parte inferior o superior derecha de la barra de estado tenga el icono de
                        un candado, lo que indica que se trata de un sitio seguro.
                    </li>
                </ol>

                <img
                    className="embedded"
                    src={httpsBrowser}
                    alt="Asegúrate de que esté el candado de conexión HTTPS en el navegador"
                />

                <ol start={5}>
                    <li>
                        Siempre que ingreses tu NIP en un cajero automático o terminal de punto de venta (TPV), tapa con
                        tu mano el teclado para evitar que se vea la secuencia que digitas.
                    </li>
                </ol>
            </Box>

            <Box className={classes.bigDescription}>
                <p>
                    Para mayor información sobre Robo de Identidad te recomendamos consultar la página{' '}
                    <a href="http://www.gob.mx/condusef" target="_blank">
                        CONDUSEF
                    </a>{' '}
                    o llamar al <strong>01-800-999-8080</strong> para que te orienten de acuerdo con tu caso.
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p>
                    En Cuenca nos preocupamos por la seguridad de tus fondos, por este motivo estamos en constante
                    desarrollo de nuevas medidas de seguridad para tu cuenta.
                </p>

                <ul>
                    <li>
                        Recuerda que la única plataforma donde podrás realizar operaciones y consultar tu cuenta es
                        directamente en la aplicación, disponible para{' '}
                        <u
                            onClick={() =>
                                window.open(
                                    'https://apps.apple.com/mx/app/cuenca-alternativa-a-un-banco/id1447995379',
                                    '_blank',
                                )
                            }
                        >
                            iOS
                        </u>{' '}
                        y{' '}
                        <u
                            onClick={() =>
                                window.open('https://play.google.com/store/search?q=Cuenca&c=apps&hl=es_MX', '_blank')
                            }
                        >
                            Android
                        </u>
                        .
                    </li>
                    <li>
                        ¿No encuentras tu tarjeta? ¡No te preocupes! Puedes activar el modo seguridad de forma inmediata
                        desde tu app. Esta función te protegerá contra intentos de cobro no reconocidos sin afectar la
                        recepción o envío de fondos en tu cuenta. Recuerda que puedes activar y desactivar el modo
                        seguridad cuantas veces lo requieras desde tu app en la sección “Tus tarjetas”
                        {'>'} “Tarjeta virtual” o “Tarjeta física” {'>'} “Activar modo seguridad”
                    </li>
                    <li>
                        Con nuestro servicio de Personas de Confianza, puedes solicitar a un amigo o familiar que te
                        ayude a bloquear tu tarjeta física y/o recuperar tu contraseña. Ellos jamás podrán acceder a tu
                        cuenta, únicamente apoyarte en el proceso.
                    </li>
                    <li>
                        Aumentamos y mejoramos la seguridad de tus compras y suscripciones en línea. Ahora el CVC de tu
                        tarjeta es dinámico y cambiará cada 5 minutos. Puedes consultarlo desde tu app en la sección
                        “Tus tarjetas” {'>'} “Tarjeta virtual” {'>'} CVC.
                    </li>
                </ul>

                <p>
                    De esta forma protegemos tu tarjeta al asegurarnos que únicamente tú autorizas los cargos al
                    ingresar un código que será distinto para cada compra y que solo tú podrás generar en la app.
                </p>

                <ul>
                    <li>
                        Si ingresaste a tu app usando validación biométrica (huella o Face ID) te pediremos una segunda
                        verificación donde deberás ingresar tu contraseña para confirmar el envío de transferencias.
                    </li>
                </ul>
            </Box>

            <Box className={classes.bigDescription}>
                <p>
                    Estos son algunos de los beneficios de seguridad que ofrece tu cuenta Cuenca. ¡Seguimos trabajando
                    para ti!
                </p>
            </Box>

            <Box className={classes.subSection}>
                <p>
                    Si crees o efectivamente la información de tu cuenta se ha visto comprometida, contacta de inmediato
                    a nuestro servicio de Soporte al Cliente a través de WhatsApp{' '}
                    <a href="https://cuenca.com/whatsapp" target="_blank">
                        https://cuenca.com/whatsapp
                    </a>{' '}
                    para brindarte orientación.
                </p>
                <ul>
                    <li>
                        <a
                            href="https://www.gob.mx/cms/uploads/attachment/file/463647/Protege_tu_identidad-2019.pdf"
                            target="_blank"
                        >
                            https://www.gob.mx/cms/uploads/attachment/file/463647/Protege_tu_identidad-2019.pdf
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.gob.mx/cms/uploads/attachment/file/463675/EVITA_LOS_ANZUELOS-2019.pdf"
                            target="_blank"
                        >
                            https://www.gob.mx/cms/uploads/attachment/file/463675/EVITA_LOS_ANZUELOS-2019.pdf
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.gob.mx/cms/uploads/attachment/file/463651/ROBO_DE_IDENTIDAD-2019.pdf"
                            target="_blank"
                        >
                            https://www.gob.mx/cms/uploads/attachment/file/463651/ROBO_DE_IDENTIDAD-2019.pdf
                        </a>
                    </li>
                    <li>
                        <a href="https://phpapps.condusef.gob.mx/fraudes_financieros/informate.php" target="_blank">
                            https://phpapps.condusef.gob.mx/fraudes_financieros/informate.php
                        </a>
                    </li>
                </ul>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(IdentityAndData);
