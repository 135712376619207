import React from 'react';

import { PageProps } from 'gatsby';

import SEO from 'components/seo';

import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';
import IAD from 'views/EducacionFinanciera/IdentityAndData';

const IdentityAndData: React.FC<PageProps<{}>> = ({ location }) => (
    <WithTheme>
        <Layout location={location}>
            <SEO title="Protección de Identidad y Datos — Educación Financiera" />

            <IAD />
        </Layout>
    </WithTheme>
);

export default IdentityAndData;
